<template>
  <div class="video_box">

    <video class="video_view" v-show="showFlv" autoplay controls id="videoElement"></video>

    <div class="m3u8_box" v-show="showM3u8">
      <video
        ref="video"
        id="videoElement"
        v-show="showM3u8"
        class="video_view video-js vjs-default-skin vjs-big-play-centered"
        controls
      >
        <source v-show="showM3u8" :src="videoUrl" id="targetVideo" />
      </video>
    </div>
    
    <div class="btn_box">
      <button class="choose_btn" @click="showVedioList()">选择监控</button>
    </div>

    <div class="videoListBox" v-show="isShowVedioList">
      <div 
        class="video_item sub_color" 
        :class="{ activeBtn: btnIndex == index }"
        v-for="(item, index) in videoList" :key="index" 
        @click="chooseVedio(item.url, index)"
      >
        {{ item.title }}
      </div>

      <!-- <el-tabs v-model="activeName">
        <el-tab-pane v-for="(item, index1) in videoList" :key="index1" :label="item.label" :name="index1 + ''">
          <div 
            class="video_item" 
            :class="{ activeBtn: btnIndex == index2 }"
            v-for="(VideoItem, index2) in item.videoList" :key="index2" 
            @click="chooseVedio(VideoItem.src, index2)"
          >
            {{ VideoItem.title }}
          </div>
        </el-tab-pane>
      </el-tabs> -->
    </div>
  </div>
</template>

<script>
  import flvjs from 'flv.js'

  export default {
    name: 'PlayVideo',

    props: {
      videoList: Array,
      url: String,
      styles: Object,
    },

    data() {
      return {
        activeName: '0',
        isShowVedioList: false,

        showFlv: false,
        showM3u8: true,

        player: null, // 用来存储当前 video
        btnIndex: 0,

        videoUrl: '',
      }
    },

    mounted() { 
      this.getVideoList()
      this.initVideo()

      // console.log('this.videoList : ', this.videoList);
    },

    watch: {
      videoList: {
        handler(newVal, oldVal) {
          this.videoUrl = ''

          this.btnIndex = 0

          this.playVedio(newVal[0].url)
        }
      },
    },

    methods: {
      // 获取 Vedio List 
      getVideoList() {
        this.$bus.$on('sendVideoList', (val) => {
          // console.log('获取 Vedio List : ', val);
          this.isShowVedioList = false
          this.videoList = val
          this.url = val[0].url
        })
      },

      chooseVedio(src) {
        var myPlayer = videojs(this.$refs.video);
        myPlayer.src([
          {
            type: "application/x-mpegURL",
            src
          }
        ]);

        myPlayer.play();
      },

      initVideo() {
        this.player = this.$video(this.$refs.video, { autoplay: true })
      },

      // 显示监控列表
      showVedioList() {
        this.isShowVedioList = true
      },
      
      // 切换视频
      playVedio(src) {
        this.btnIndex = 0

        let formatIndex = src.search(/\?/i);

        let format = src.slice(formatIndex-4, formatIndex)

        // console.log(format);    // m3u8 .flv

        // 播放 .flv 格式的视频
        if (format == '.flv') {
          this.showFlv = true
          this.showM3u8 = false

          var flvPlayer = flvjs.createPlayer({
            type: 'flv',
            url: src
          });

          var videoElement = document.getElementById('videoElement');

            flvPlayer.attachMediaElement(videoElement);
            flvPlayer.load();
            flvPlayer.play();

          } else {
            this.showFlv = false
            this.showM3u8 = true

            // 播放 .m3u8 格式的视频
            let options = {
              src,
              autoplay: true,
              type: 'application/x-mpegURL'
            }

            this.player.src(options);
            this.player.load();
          }
        },

      // 切换视频
      chooseVedio(src, index2) {

        this.isShowVedioList = false
        this.btnIndex = index2

        let formatIndex = src.search(/\?/i);

        let format = src.slice(formatIndex-4, formatIndex)

        // console.log(format);    // m3u8 .flv

        // 播放 .flv 格式的视频
        if (format == '.flv') {
          this.showFlv = true
          this.showM3u8 = false

          var flvPlayer = flvjs.createPlayer({
              type: 'flv',
              url: src
          });

          var videoElement = document.getElementById('videoElement');

          flvPlayer.attachMediaElement(videoElement);
          flvPlayer.load();
          flvPlayer.play();

        } else {
          this.showFlv = false
          this.showM3u8 = true
        // 播放 .m3u8 格式的视频
          let options = {
            src,
            autoplay: true,
            type: 'application/x-mpegURL'
          }

          this.player.src(options);
          this.player.load();
        }

      }
    }
  }


</script>

<style scoped lang="scss">
.activeBtn { color: red; }

.video_box {
  width: 100%;
  height: 100%;
  position: relative;
  // border: 1px solid red;
  .video_view { width: 100%; height: 100%; }

  .m3u8_box {
    width: 100%;
    height: 100%;
  }

  .btn_box {
    padding: 0 5px;
    position: absolute;
    top: 30px;
    right: -150px;
    font-size: 20px;

    .choose_btn {
      padding: 5px;
      border: none;
      border-radius: 3px; 
      margin-bottom: 3px; 
      margin-right: 5px;
      background-color: #212121;
      color: #fff;
    }
    .choose_btn:hover { cursor: pointer; }

    .play_btn { 
      padding: 5px;
      border: none;
      border-radius: 3px; 
      margin-bottom: 3px; 
      margin-right: 5px;
    }
    .play_btn:hover { cursor: pointer; }
  }

  .videoListBox {
    width: 50%;
    height: 90%;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #eee;
    background-color: rgba(#212121, 0.8);
    color: #fff;
    border-radius: 5px;
    overflow-y: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;

    .video_item {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
    .video_item:hover { cursor: pointer; }
  }
}
</style>

<style>
.el-tabs__item { color: #fff !important; text-align: center !important; }
.el-tabs__item.is-active { color: #409eff !important; }
</style>

