<template>
  <div class="shi_fan_box relative">
    <span class="absolute left_20 top_10 color_255_280_0 cursor" @click="back()">返回</span>
    <span class="absolute left_80 top_10 color_0bfb83">技术支持：内蒙古牧途信息技术有限公司</span>

    <div class="mu_hu_box absolute left_center z_999">
      <mu-hu-list />
    </div>

    <div class="main_box w_full absolute top_70 flex_row_between">
      <!-- 左 -->
      <div class="w_30_p h_full flex_column_around">
        <div class="left_box1 w_full h_24_p relative">
          <span class="box_title left_25_p top_-10">饲料统计</span>

          <div class="absolute top_20_p left_10_p">
            <div class="text_1 color_08c2fa">精饲料存量 800 袋</div>
            <div class="text_1 color_08c2fa">干草存量 5 吨</div>
            <div class="text_1 color_08c2fa">青储存量 10 吨</div>
          </div>

          <div class="absolute bottom_10 left_10_p">
            <div class="fs_14 color_08c2fa">
              <span class="color_orange">棚内温度 18℃</span> 
              <span class="color_ff2c64 ml_40">甲烷含量 0.3%</span>
            </div> 
            <div class="fs_14 color_08c2fa">
              <span class="color_08c2fa">棚内温度 18℃</span> 
              <span class="color_0bfb83 ml_40">甲烷含量 0.3%</span>
            </div>
          </div>

          <div class="si_liao_bing_tu w_50_p h_70_p absolute right_10">
            <bing-tu :value="data1" />
          </div>
        </div>

        <div class="left_box2 w_full h_24_p relative">
          <span class="box_title left_25_p top_0">存栏统计</span>

          <div class="absolute top_20_p left_10_p">
            <div class="text_1 color_255_280_0">西门塔尔牛 16 头</div>
            <div class="text_1 color_255_280_0">短角牛 7 头</div>
            <div class="text_1 color_255_280_0">其他 10 头</div>
          </div>

          <span class="box_title right_20_p top_10_p">饲料日消耗量</span>

          <div class="w_55_p h_70_p absolute top_20_p right_10">
            <line-chart class="si_liao_xiao_hao_liang w_250 h_180" :value="data2" />
          </div>
        </div>

        <div class="left_box3 w_full h_24_p relative">
          <span class="box_title right_12_p top_28_p">炭转肥</span>

          <div class="w_70_p h_70_p flex_row_around absolute top_20_p left_5_p">
            <div class="w_30_p">
              <div class="w_full text_center sub_color">羊棚</div>
              <kong-xin-bing-tu :value="data3" />
            </div>
            <div class="w_30_p">
              <div class="w_full text_center sub_color">牛棚</div>
              <kong-xin-bing-tu :value="data4" />
            </div>
            <div class="w_30_p">
              <div class="w_full text_center sub_color">马棚</div>
              <kong-xin-bing-tu :value="data5" />
            </div>
          </div>
        </div>
        <div class="left_box4 w_full h_25_p relative">
          <span class="box_title left_25_p top_5_p">干湿分离</span>

          <div class="absolute top_40_p right_5_p">
            <div class="text_1 color_08c2fa">设备状态 ：未运行</div>
            <div class="text_1 color_08c2fa">本次加工量 ：300KG</div>
            <div class="text_1 color_08c2fa">累计加工量 ：6吨</div>
          </div>

          <div class="w_55_p h_70_p absolute top_20_p left_5_p">
            <zhu-zhuang-tu :value="data6" />
          </div>
        </div>
      </div>

      <!-- 中间 -->
      <div class="w_37_p h_full flex_column_around">
        <div class="center_1 w_95_p m_0_auto h_45_p mt_30 relative">
          <div class="video_box absolute top_10_p left_10_p">
            <play-video />
          </div>
        </div>
        <div class="center_2 w_full h_52_p">
          <yang-zhi-dang-an />
        </div>
      </div>

      <!-- 右 -->
      <div class="w_30_p h_full flex_column_around">
        <div class="right_box1 w_full h_24_p relative">
          <span class="box_title right_25_p top_-10">自动饲喂</span>

          <div class="w_90_p p_5 absolute top_0 left_10_p">
            <si-wei-kai-guan />
          </div>
        </div>
        <div class="right_box2 w_full h_24_p relative">
          <span class="box_title right_25_p top_0">恒温饮水</span>

          <div class="w_90_p p_5 absolute top_20_p left_10_p">
            <yin-shui-kai-guan />
          </div>
        </div>
        <div class="right_box3 w_full h_24_p relative">
          <span class="box_title left_9_p top_27_p">自动消杀</span>

          <div class="w_50_p p_5 absolute top_20_p right_10_p">
            <xiao-sha-kai-guan />
          </div>
        </div>
        <div class="right_box4 w_full h_25_p relative">
          <span class="box_title right_25_p top_10">自动清粪</span>

          <div class="qing_fen_img absolute top_25_p left_10_p">
            <img class="full" :src="qingFenImg" alt="">
          </div>

          <div class="w_50_p p_5 absolute top_20_p right_5_p">
            <qing-fen-kai-guan />
          </div>

          <div class="p_5 absolute bottom_10 right_20">
            <div class="fs_16 color_08c2fa">
              <span class="text_2 color_08c2fa">今日清理次数 5</span> 
              <span class="text_2 color_08c2fa ml_30">累计清理次数123</span> 
              <span class="text_2 color_08c2fa ml_30">累计清理量 500KG</span> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BingTu from './charts/bing-tu.vue'
  import XianXingTu from './charts/xian-xing-tu.vue'
  import LineChart from './charts/line-chart.vue'
  import KongXinBingTu from './charts/kong-xin-bing-tu.vue'
  import ZhuZhuangTu from './charts/zhu-zhuang-tu.vue'
  import MuHuList from './mu-hu-list.vue'
  import PlayVideo from './play-video.vue'
  import YangZhiDangAn from './yang-zhi-dang-an.vue'
  import SiWeiKaiGuan from './si-wei-kai-guan.vue'
  import YinShuiKaiGuan from './yin-shui-kai-guan.vue'
  import XiaoShaKaiGuan from './yin-shui-kai-guan.vue'
  import QingFenKaiGuan from './xiao-sha-kai-guan.vue'

  export default {
    name: 'DemonstrationMakido',
    components: { BingTu, XianXingTu, LineChart, KongXinBingTu, ZhuZhuangTu, MuHuList, PlayVideo, YangZhiDangAn, SiWeiKaiGuan, YinShuiKaiGuan, XiaoShaKaiGuan, QingFenKaiGuan },
    data() {
      return {
        qingFenImg: require('@/assets/images/shi-fan-dian/qing-fen.png'),
        data1: {},
        data2: {},
        data3: {},
        data4: {},
        data5: {},
        data6: {},
      }
    },
    mounted() {
      setTimeout(() => {
        this.initChart()
      }, 100)
    },
    methods: {
      initChart() {
        this.data1 = {
          title: '',
          unit: '',
          data: [
            {"name": "青储", "value": 50, "unit": ''},
            {"name": "干草", "value": 20, "unit": ''},
            {"name": "精饲料", "value": 30, "unit": ''}
          ]
        }

        this.data2 = {
          x: [1, 2, 3, 4, 5],
          y: [100, 130, 140, 150, 160]
        }

        this.data3 = {
          title: '',
          unit: '',
          data: [
            {"name": "", "value": 43, "unit": ''},
            {"name": "", "value": 54, "unit": ''},
            {"name": "", "value": 54, "unit": ''},
          ]
        }

        this.data4 = {
          title: '',
          unit: '',
          data: [
            {"name": "", "value": 12, "unit": ''},
            {"name": "", "value": 54, "unit": ''},
            {"name": "", "value": 87, "unit": ''},
          ]
        }

        this.data5 = {
          title: '',
          unit: '',
          data: [
            {"name": "", "value": 63, "unit": ''},
            {"name": "", "value": 14, "unit": ''},
            {"name": "", "value": 24, "unit": ''},
          ]
        }

        this.data6 = {
          yName: '',
          x: ['一季度', '二季度', '三季度', '四季度'],
          y: [18, 39, 12, 43]
        }
      },

      back() {
        this.$router.back()
      }
    }
  }
</script>

<style lang="less" scoped>
  .shi_fan_box {
    width: 100vw;
    height: 100vh;
    background-image: url('../../../assets/images/shi-fan-dian/bgi.png');
    background-size: 100% 100%;

    .main_box {
      height: calc(100% - 70px);
    }
  }
  .left_box1 {
    background-image: url('../../../assets/images/shi-fan-dian/left1.png');
    background-size: 100% 100%;
  }
  .left_box2 {
    background-image: url('../../../assets/images/shi-fan-dian/left2.png');
    background-size: 100% 100%;
  }
  .left_box3 {
    background-image: url('../../../assets/images/shi-fan-dian/left3.png');
    background-size: 100% 100%;
  }
  .left_box4 {
    background-image: url('../../../assets/images/shi-fan-dian/left4.png');
    background-size: 100% 100%;
  }
  .center_1 {
    background-image: url('../../../assets/images/shi-fan-dian/center1.png');
    background-size: 100% 100%;
  }
  .center_2 {
    background-image: url('../../../assets/images/shi-fan-dian/center3.png');
    background-size: 100% 100%;
  }
  .right_box1 {
    background-image: url('../../../assets/images/shi-fan-dian/right1.png');
    background-size: 100% 100%;
  }
  .right_box2 {
    background-image: url('../../../assets/images/shi-fan-dian/right2.png');
    background-size: 100% 100%;
  }
  .right_box3 {
    background-image: url('../../../assets/images/shi-fan-dian/right3.png');
    background-size: 100% 100%;
  }
  .right_box4 {
    background-image: url('../../../assets/images/shi-fan-dian/right4.png');
    background-size: 100% 100%;
  }

  // 标题
  .box_title { font-size: 20px; font-weight: bold; color: rgb(255, 200, 0); position: absolute; }
</style>