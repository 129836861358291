<template>
  <div class="text">
    <!-- <span 
      v-show="paramsVal == '全部'"
      class="mu_hu_name text_1" 
      :class="{ activeColor : activeMuHuIndex == index }"
      v-for="(item, index) in muhuList" :key="index" 
      @click="handleClickMarqueeText(index)"
    >
      <span>{{ `牧户 ${index + 1}` }}</span> 
    </span> -->

    <span 
      v-show="paramsVal == '全部'" 
      class="mu_hu_name text_1" 
      :class="{ activeColor : activeMuHuIndex == 0 }" 
      @click="handleClickMarqueeText(0)"
    >
      敖特根巴特尔
    </span> 

    <span 
      v-show="paramsVal == '全部'" 
      class="mu_hu_name text_1" 
      :class="{ activeColor : activeMuHuIndex == 1 }" 
      @click="handleClickMarqueeText(1)"
    >
      斯琴巴特尔
    </span> 

    <span 
      v-show="paramsVal == '全部'" 
      class="mu_hu_name text_1" 
      :class="{ activeColor : activeMuHuIndex == 2 }" 
      @click="handleClickMarqueeText(2)"
    >
      孟和那顺
    </span> 

    <span 
      v-show="paramsVal == '全部'" 
      class="mu_hu_name text_1" 
      :class="{ activeColor : activeMuHuIndex == 3 }" 
      @click="handleClickMarqueeText(3)"
    >
      达日夫
    </span> 

    <span class="mu_hu_name" v-show="paramsVal && paramsVal !== '全部'" @click="paramsVal = '全部'">全部</span>
    <span class="mu_hu_name" v-show="paramsVal == '牧户1'" @click="handleClickMarqueeText(0)">敖特根巴特尔</span> 
    <span class="mu_hu_name" v-show="paramsVal == '牧户2'" @click="handleClickMarqueeText(1)">斯琴巴特尔</span> 
    <span class="mu_hu_name" v-show="paramsVal == '牧户3'" @click="handleClickMarqueeText(2)">孟和那顺</span> 
    <span class="mu_hu_name" v-show="paramsVal == '牧户4'" @click="handleClickMarqueeText(3)">达日夫</span> 
  </div>
</template>

<script>
import { getMuHuList } from '@/config/mu-hu-request';

export default {
  name: "MuHuList",
  components: {},
  props: {
    value: Object,
    ispreview: Boolean
  },
  data() {
    return {
      paramsVal: '',
      
      muhuList: [],
      activeMuHuIndex: -1,
      timer: null,
      showAll: false,
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  mounted() {
    this.getMuHuData()
  },
  methods: {
     // 获取牧户 List
     async getMuHuData() {
      let res = await getMuHuList();

      if (res.code == 200) {
        for (let i in res.data) {
          let arr = [8, 9, 10, 15]
          if (arr.includes(res.data[i].id)) {
            this.muhuList.push(res.data[i])
          }
        }

        let params = this.$route.params.val || ''
        console.log('参数 : ', params);
        this.paramsVal = params

        let index = 0
        let id = 0

        if (params !== '全部') {
          this.showAll = true
          if (params == '牧户1') {
            index = 0
            id = 8
          } else if (params == '牧户2') {
            index = 1
            id = 9
          } else if (params == '牧户3') {
            index = 2
            id = 10
          } else if (params == '牧户4') {
            index = 3
            id = 15
          }
          this.$bus.$emit('clickUserName', id)
          this.$bus.$emit('sendVideoList', this.muhuList[index].monitors)

        } else {
          setTimeout(() => {
            this.activeMuHuIndex = 0
            // 发送 user_id
            this.$bus.$emit('clickUserName', this.muhuList[0].id)

            // 向 Video.vue 发送 监控 list
            this.$bus.$emit('sendVideoList', this.muhuList[0].monitors)
          }, 500)
        }
      } 
    },

    // 点击 用户名称
    handleClickMarqueeText(index) {
      clearInterval(this.timer)

      this.activeMuHuIndex = index
      // console.log('牧户 idnex : ', index);

      // 发送 user_id
      this.$bus.$emit('clickUserName', this.muhuList[index].id)

      // 向 Video.vue 发送 监控 list
      this.$bus.$emit('sendVideoList', this.muhuList[index].monitors)
    },

    
  }
};
</script>

<style scoped lang="scss">

.activeColor { color: red !important; font-weight: 500; }
.text {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mu_hu_name {
  width: 150px;
  // height: 40px;
  margin: 0 10px;
  cursor: pointer;
  color: #fefe0b;
  // font-size: 22px;
}


</style>
