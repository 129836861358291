<template>
  <div class="text" :height="240">
    <!-- 监听鼠标滚动 -->
    <el-carousel class="swiper_yang_zhi_dang_an" :interval="3000" :autoplay="false" indicator-position="none" ref="swaiper-1">
      <el-carousel-item class="yang_zhi_box" v-for="(item, index) in yangZhiDangAnList" :key="index" >
        <div class="yang_zhi_top">
          <div class="left_box">
            <!-- <img :src="yang_zhi_def_img" alt=""> -->
            <span class="text_1 left_title">健康率</span> 

            <div class="bai_fen_bi_box">
              <percentYangZhi class="bai_fen_bi" :value="80 + index"  />
            </div>
          </div>

          <div class="center_box">
            <!-- <img 
              v-if="item.files && item.files.length" 
              :src="img_path + item.files[0].path || oxImg"
              alt="图片未上传"
            > -->
            <img v-if="index == 0" :src="oxImg1" alt="">
            <img v-if="index == 1" :src="oxImg2" alt="">
            <img v-if="index == 2" :src="oxImg3" alt="">
            <img v-if="index == 3" :src="oxImg4" alt="">
            <img v-if="index == 4" :src="oxImg5" alt="">
            <img v-if="index == 5" :src="oxImg6" alt="">
            <img v-if="index == 6" :src="oxImg7" alt="">
            <img v-if="index == 8" :src="oxImg8" alt="">
            <img v-if="index == 9" :src="oxImg1" alt="">
            <img v-if="index == 10" :src="oxImg2" alt="">
            <img v-if="index == 11" :src="oxImg3" alt="">
            <img v-if="index == 12" :src="oxImg4" alt="">
            <img v-if="index == 13" :src="oxImg5" alt="">
            <img v-if="index == 14" :src="oxImg6" alt="">
            <img v-if="index == 15" :src="oxImg7" alt="">
            <img v-if="index == 16" :src="oxImg8" alt="">
            <img v-if="index == 17" :src="oxImg2" alt="">
            <img v-if="index == 18" :src="oxImg1" alt="">
          </div>

          <div class="right_box">
            <div class="niu_biao_hao color_255_280_0">{{ item.num }}</div>
            <div class="text_1 mini_text color_255_280_0">编号: {{ item.num }}</div>
            <div class="text_1 mini_text color_255_280_0">类型 : {{ item.animalTypeName || '' }}</div>
            <div class="text_1 mini_text color_255_280_0">年龄: {{ item.age }} 岁</div>
            <div class="text_1 mini_text color_255_280_0">体重 : {{ item.weight }} KG</div>
            <!-- <div class="mini_text">健康状况 : {{ item.weight }}</div> -->
            <div class="text_1 mini_text color_255_280_0">疫苗接种 : {{ item.morbidity }}</div>
            <div class="text_1 mini_text color_255_280_0">配种次数 : {{ item.breedingCount }}</div>
            <div class="text_1 jin_chang_ri_qi mini_text color_255_280_0">进场日期 : {{ item.createTime }}</div>
          </div>
        </div>

        <div class="yang_zhi_bottom">
          <div class="ti_zhong_jian_ce_com">
            <tiZhongJiainCe />
          </div>
        </div>
        
        
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { getYangZhiDangAn } from '@/config/mu-hu-request';
import percentYangZhi from './percent-yang-zhi.vue'
import tiZhongJiainCe from './ti-zhong-jian-ce.vue'

const IMG_PATH = process.env.TE_API

export default {
  name: "WidgetText",
  components: { percentYangZhi, tiZhongJiainCe },
  props: {
    value: Object,
    ispreview: Boolean
  },
  data() {
    return {
      oxImg1: require('@/assets/images/ba-lin-you-qi/1.jpg'),
      oxImg2: require('@/assets/images/ba-lin-you-qi/2.jpg'),
      oxImg3: require('@/assets/images/ba-lin-you-qi/3.jpg'),
      oxImg4: require('@/assets/images/ba-lin-you-qi/1.jpg'),
      oxImg5: require('@/assets/images/ba-lin-you-qi/2.jpg'),
      oxImg6: require('@/assets/images/ba-lin-you-qi/3.jpg'),
      oxImg7: require('@/assets/images/ba-lin-you-qi/3.jpg'),
      oxImg8: require('@/assets/images/ba-lin-you-qi/3.jpg'),
      oxImg9: require('@/assets/images/ba-lin-you-qi/3.jpg'),
      img_path: IMG_PATH,
      yang_zhi_def_img: require('@/assets/images/shi-fan-dian/yang_zhi_img.png'),
      options: {},
      optionsData: {},
      yangZhiDangAnList: [],
      percentData: {
        data: {
          dataType: "staticData",
          refreshTime: 5000,
          staticData: [
            { num: 34 }
          ]
        },
      }
    };
  },
  created() {
    //  >>>>>>>>> 牧户下 暂时没有养殖档案, 无法通过 id 进行查找 <<<<<<<<<<<  2023-06-09

    // this.$bus.$on('clickUserName', (id) => {
    //   this.getYangZhiDangAnData(id)
    // })
  },

  mounted() {
    this.getYangZhiDangAnData('')
  },
  methods: {
     // 获取养殖档案
     async getYangZhiDangAnData(id) {

      let data = { id: id }
      let res = await getYangZhiDangAn(data);
      if (res.code == 200) {
        this.yangZhiDangAnList = res.data
        console.log('养殖档案 : ', res.data);
      } 
    },
  }
};
</script>

<style scoped lang="scss">

.activeColor { color: red; font-weight: 500; }
.text::-webkit-scrollbar {
  display: none;
}

.text {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  .swiper_yang_zhi_dang_an { 
    width: 100%;
    height: 100% !important;
    // border: 1px solid blue;
    .yang_zhi_box {
      width: 100%;
      // height: 200% !important;
      // overflow: hidden;
      // border: 1px solid blue;

      .yang_zhi_top {
        width: 100%;
        height: 50% !important;
        border: 1px solid #212121;
      
        .left_box {
          width: 30%;
          height: 100%;
          float: left;
          // border: 1px solid blueviolet;

          .left_title {
            width: 100%;
            text-align: center;
            color: rgb(250, 212, 0);
            font-size: 18px;
          }

          .bai_fen_bi_box {
            width: 100%;
            height: 100%;
            position: relative !important;
            // text-align: center;
            // display: flex;
            // align-items: center;
            // border: 1px solid #f00;

            .bai_fen_bi { position: absolute !important;  }
          }
        }
        .center_box {
          width: 40%;
          height: 100%;
          float: left;
          // border: 1px solid greenyellow;

          img { width: 100%; height: 100%; }
        }
        .right_box {
          width: 30%;
          height: 100%;
          float: left;
          // border: 1px solid yellow;
          padding: 0 10px;

          .niu_biao_hao { width: 100%; text-align: center; }
          .mini_text { width: 100%; text-align: left;  }
        }
      }
      
      .yang_zhi_bottom {
        width: 100%;
        height: 100%;

        .ti_zhong_jian_ce_com {
          width: 100%;
          height: 100%;
        }
      }
    }
  }  
}

</style>

<style>
.swiper_yang_zhi_dang_an > .el-carousel__container { height: 230px !important; }

.bai_fen_bi > .echarts { 
  width: 180px !important; 
  height: 180px !important; 
  position: absolute !important; 
  top: 0 !important; 
  left: 17px !important; 
}
/* .ti_zhong_jian_ce_com > .echarts {
  width: 180px !important; 
  height: 180px !important;
  position: absolute !important; 
  top: 300px !important; 
  left: 17px !important;
} */
</style>
